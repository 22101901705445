import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/bootstrap-v4.css";

let notyParams = {
    type: "info",
    layout: "topRight",
    theme: 'bootstrap-v4',
    timeout: 2000,
    killer: true
};

function notySuccess(msg = '') {
    notyParams.type = "success";
    notyParams.text = msg ? msg : "Succès";

    new Noty(notyParams).show();
}

function notyError(msg = '') {
    notyParams.type = "error";
    notyParams.text = msg ? msg : "Erreur ";

    new Noty(notyParams).show();
}

function notyInfo(msg = '') {
    notyParams.type = "info";
    notyParams.text = msg;

    new Noty(notyParams).show();
}

function notyAlert(msg = '') {
    notyParams.type = "alert";
    notyParams.text = msg;

    new Noty(notyParams).show();
}

function notyWarning(msg = '') {
    notyParams.type = "warn";
    notyParams.text = msg;

    new Noty(notyParams).show();
}

function noty(type = "info", msg = '', timeout = 2000) {
    notyParams.type = type;
    notyParams.text = msg;

    new Noty(notyParams).show();
}

export { notySuccess, notyError, notyInfo, notyAlert, notyWarning, noty };
