export default function professionalRecruitment (state = {}, action) {
	switch(action.type) {
        case "SAVE_AGENT": {
		    return Object.assign({}, state, {
		    	agent: action.agent
		    });
        }
		default:
			return state;
	}
}