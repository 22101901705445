export default function secretary(state = {}, action) {
	switch(action.type) {
        case "FIND_SECRETARIES_REQUEST":
			return Object.assign({}, state, {
				isGettingsecretaries: true
			});
		case "FIND_SECRETARIES_SUCCESS":
			return Object.assign({}, state, {
				secretaries: action.secretaries,
                secretariesCount: action.secretariesCount,
				isGettingsecretaries: false
			});
		case "FIND_SECRETARIES_FAILURE":
			return Object.assign({}, state, {
				isGettingsecretaries: false
			});
		default:
			return state;
	}
}