const rootUrl = process.env.REACT_APP_BE_URL || "http://localhost:7000";

const APIUrl = {
    rootUrl:         rootUrl,

    // Authentication
    getBearerToken:  rootUrl + "/oauth/token",                                           // GET
    login:           rootUrl + "/auth/login",                                            // POST
        
    // Global SINGLES
    add:             rootUrl + "/{{objName}}",                                           // POST
    get:             rootUrl + "/{{objName}}",                                           // GET
    upd:             rootUrl + "/{{objName}}",                                           // PUT
    remove:          rootUrl + "/{{objName}}",                                           // DELETE

    //User
    checkEmail:          rootUrl + "/user/email",                                        // POST
    checkName:           rootUrl + "/user/name",                                        // POST,
    checkPhone:           rootUrl + "/user/phone",                                        // POST,
    lastInternalNumber:    rootUrl + "/user/last-internal-number",                       // GET,
    
    // Global MULTI
    gets:             rootUrl + "/{{objName}}s",                                         // GET,

    // Professional Recruitment Contract
    downloadProfessionalRecruitmentContract:  rootUrl + "/professionalRecruitmentContract/download/professional_id/{{professional_id}}/study_id/{{study_id}}/center_id/{{center_id}}/",    // GET
    downloadProfessionalRecruitmentContractById:  rootUrl + "/professionalRecruitmentContractById/download/_id/{{_id}}/",    // GET
};

export default APIUrl;
