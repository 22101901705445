import APIUrl from "../APIUrl";
import axios from "axios";
import Logger from "../../util/Logger";
import { notyError } from "../../util/NotyUtil";

const extractUrl = (obj) => {
    var splittedUrl;
    var objName = obj;
    if (obj.indexOf("/") !== -1) {
        splittedUrl = objName.split("/");
        objName = splittedUrl[0];
        splittedUrl.shift();
    }

    return { objName: objName, params: splittedUrl };
};

/**
 * Object returned by server should have the same name has "objname" 
 * Response will be added to state.global if name is objname is correct
 */


// ================================================================================
// =================================== SINGLE =====================================
// ================================================================================

export const CLEAR = "CLEAR";
export const GET = "GET";
export const UPD = "UPD";
export const REMOVE = "REMOVE";

export function clearAction(objName) { return { type: CLEAR, objName: objName } };
export function getAction(objName, obj) { return { type: GET, objName: objName, obj: obj } };
export function updAction(objName, obj) { return { type: UPD, objName: objName, obj: obj } };
export function removeAction(objName, objId) { return { type: REMOVE, objName: objName, objId: objId } };


export const clear = function (path, next) {
    var extracted = extractUrl(path);
    const { objName } = extracted;

    return function (dispatch) {
        dispatch(clearAction(objName));
        dispatch(next);
    };
};

export const add = function (path, obj, callback, errorCbk) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.add.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        return axios.post(url, obj)
            .then(function (response) {
                Logger.log(">> Global Axios action - ADD!");
                Logger.log(response.data);

                if (response && response.data) {
                    const obj = response.data.message[objName];
                    dispatch(getAction(objName, obj));
                    if (callback) callback(obj);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                notyError();
                console.error(err);
                if(errorCbk) errorCbk();
            });
    };
};

export const get = function (path, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.get.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;


        return axios.get(url)
            .then(function (response) {
                Logger.log(">> Global Axios action - GET!");
                Logger.log(response.data);

                if (response && response.data) {
                    const obj = response.data.message[objName];
                    dispatch(getAction(objName, obj));
                    if (callback) callback(obj);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const getBy = function (path, fields, values, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.get.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        if (!Array.isArray(fields)) fields = [fields];
        if (!Array.isArray(values)) values = [values];

        for (let i = 0; i < fields.length; i++) {
            url += "/" + fields[i] + "/" + values[i];
        }

        return axios.get(url)
            .then(function (response) {
                Logger.log(">> Global Axios action - GET BY (" + fields + ": " + values + ") !");
                Logger.log(response.data);

                if (response && response.data) {
                    const obj = response.data.message[objName];
                    dispatch(getAction(objName, obj));
                    if (callback) callback(obj);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const upd = function (path, obj, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.upd.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        return axios.put(url, obj)
            .then(function (response) {
                Logger.log(">> Global Axios action - UPD!");
                Logger.log(response.data);

                if (response && response.data) {
                    const obj = response.data.message[objName];
                    dispatch(updAction(objName, obj));
                    if (callback) callback(obj);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                notyError();
                throw err;
            });
    };
};

 /** @description Update Only One state */
export const updObject = function(objName, obj){
    return function (dispatch) {
       dispatch(updAction(objName, obj));
    };
};

export const remove = function (path, obj, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.remove.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        return axios.delete(url, obj)
            .then(function (response) {
                Logger.log(">> Global Axios action - REMOVE!");
                Logger.log(response.data);

                dispatch(removeAction(objName, obj._id));
                if (callback) callback(obj._id);
            })
            .catch(function (err) {
                notyError();
                throw err;
            });
    };
};

// ================================================================================
// ==================================== MANY ======================================
// ================================================================================

export const ADDS = "ADDS";
export const CLEARS = "CLEARS";
export const GETS = "GETS";
export const UPDS = "UPDS";
export const REMOVES = "REMOVES";

export function addsAction(objName, obj, count) { return { type: ADDS, objName: objName + "s", obj: obj, count: count } };
export function clearsAction(objName) { return { type: CLEARS, objName: objName + "s" } };
export function getsAction(objName, obj, count) { return { type: GETS, objName: objName + "s", obj: obj, count: count } };
export function updsAction(objName, obj) { return { type: UPDS, objName: objName + "s", obj: (Array.isArray(obj)) ? obj : [obj] } };
export function removesAction(objName, objId) { return { type: REMOVES, objName: objName + "s", objId: objId } };


export const adds = function (path, obj, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.add.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        return axios.post(url, obj)
            .then(function (response) {
                Logger.log(">> Global Axios action - ADDS!");
                Logger.log(response.data);

                if (response && response.data) {
                    const obj = response.data.message[objName];
                    dispatch(addsAction(objName, [obj]));
                    if (callback) callback(obj);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                notyError();
                throw err;
            });
    };
};

export const clears = function (path, next) {
    var extracted = extractUrl(path);
    const { objName } = extracted;

    return function (dispatch) {
        dispatch(clearsAction(objName));
        if (next) dispatch(next);
    };
};

export const gets = function (path, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.gets.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        return axios.get(url)
        .then(function (response) {
            
            Logger.log(">> Global Axios action - GETS!");
            Logger.log(response.data);

            if (response && response.data) {
                const obj = response.data.message[objName + "s"];
                    const count = obj.length;
                    dispatch(getsAction(objName, obj, count));
                    if (callback) callback(obj);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const getsBy = function (path, fields, values, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.gets.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        if (!Array.isArray(fields)) fields = [fields];
        if (!Array.isArray(values)) values = [values];

        for (let i = 0; i < fields.length; i++) {
            url += "/" + fields[i] + "/" + values[i];
        }

        return axios.get(url)
            .then(function (response) {
                Logger.log(">> Global Axios action - GETS BY (" + fields + ": " + values + ") !");
                Logger.log(response.data);

                if (response && response.data) {
                    const obj = response.data.message[objName + "s"];
                    const count = response.data.message["count"];

                    dispatch(getsAction(objName, obj, count));
                    if (callback) callback(obj, count);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                throw err;
            });
    };
};

export const upds = function (path, obj, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.upd.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;
        return axios.put(url, obj)
            .then(function (response) {
                Logger.log(">> Global Axios action - UPDS!");
                Logger.log(response.data);

                if (response && response.data) {
                    const obj = response.data.message[objName];
                    dispatch(updsAction(objName, obj));
                    if (callback) callback(obj);
                } else {
                    if (callback) callback();
                }
            })
            .catch(function (err) {
                notyError();
                throw err;
            });
    };
};

export const removes = function (path, obj, callback) {
    var extracted = extractUrl(path);
    const { objName, params } = extracted;

    return function (dispatch) {
        var url = APIUrl.remove.replace("{{objName}}", objName);

        // Add potential query suffixes (if any)
        if (params) for (let p of params) url += "/" + p;

        return axios.delete(url + "/" + obj._id)
            .then(function (response) {
                Logger.log(">> Global Axios action - REMOVES!");
                Logger.log(response.data);

                dispatch(removesAction(objName, obj._id));
                if (callback) callback(obj._id);
            })
            .catch(function (err) {
                notyError();
                throw err;
            });
    };
};
