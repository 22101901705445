/**
 * @param {*} state 
 * @param {Object} action 
 */
export default function clinicalStudy(state = {}, action) {
	switch(action.type) {
		case "CLINICAL_STUDY_POST_REQUEST":
			return Object.assign({}, state, {
				isPostingClinicalStudy: true
			});
		case "CLINICAL_STUDY_POST_SUCCESS":
			let listOfClinicalStudyAdd = Array.from(state.listOfClinicalStudy)
			listOfClinicalStudyAdd.push(action.clinicalStudy);
			return Object.assign({}, state, {
				isPostingClinicalStudy: false,
				listOfClinicalStudy: listOfClinicalStudyAdd
			});
		case "CLINICAL_STUDY_POST_FAILURE":
			return Object.assign({}, state, {
				isPostingClinicalStudy: false
			});
		case "UPDATE_CLINICAL_STUDY_POST_REQUEST":
			return Object.assign({}, state, {
				isUpdatedClinicalStudy: false
			});
		case "UPDATE_CLINICAL_STUDY_POST_SUCCESS":
			let listOfClinicalStudyUpdatedFilter = Array.from(state.listOfClinicalStudy, (elem) => {
				if (elem._id === action.clinicalStudy._id) {
					return action.clinicalStudy;
				}
				return elem;
			})
			return Object.assign({}, state, {
				isUpdatedClinicalStudy: true,
				listOfClinicalStudy: listOfClinicalStudyUpdatedFilter,
			});
		case "UPDATE_CLINICAL_STUDY_POST_FAILURE":
			return Object.assign({}, state, {
				isUpdatedClinicalStudy: false
			});
		case "CLINICAL_STUDY_ACRONYME_REQUEST":
			return Object.assign({}, state, {
				isCheckingAcronyme: true
			});
		case "CLINICAL_STUDY_ACRONYME_SUCCESS":
			return Object.assign({}, state, {
				isCheckingAcronyme: false,
				isAcronymeExists: action.result
			});
		case "CLINICAL_STUDY_ACRONYME_FAILURE":
			return Object.assign({}, state, {
				isCheckingAcronyme: false
			});
		case "FIND_CLINICAL_STUDY_REQUEST":
			return Object.assign({}, state, {
				isGettingClinicalStudy: true
			});
		case "FIND_CLINICAL_STUDY_SUCCESS":
			return Object.assign({}, state, {
				listOfClinicalStudy: action.listOfClinicalStudy,
				isGettingClinicalStudy: false
			});
		case "FIND_CLINICAL_STUDY_FAILURE":
			return Object.assign({}, state, {
				isGettingClinicalStudy: false
			});
		case "UPDATE_STATUT_PATIENT_CLINICAL_STUDY_POST_REQUEST":
			return Object.assign({}, state, {
				isUpdatedStatutPatient: false
			});
		case "UPDATE_STATUT_PATIENT_CLINICAL_STUDY_POST_SUCCESS":
			return Object.assign({}, state, {
				isPatientStatusUpdated: true,
				listOfClinicalStudy: addUpdatedPatientToStudy(state, action)
			});
		case "UPDATE_STATUT_PATIENT_CLINICAL_STUDY_POST_FAILURE":
			return Object.assign({}, state, {
				isUpdatedStatutPatient: false
			});
		case "UPDATE_CRITERIA_PATIENT_CLINICAL_STUDY_POST_REQUEST":
			return Object.assign({}, state, {
				isUpdatedCriteraPatient: false
			});
		case "UPDATE_CRITERIA_PATIENT_CLINICAL_STUDY_POST_SUCCESS":
			return Object.assign({}, state, {
				isUpdatedCriteraPatient: true,
				listOfClinicalStudy: addUpdatedPatientToStudy(state, action)
			});
		case "UPDATE_CRITERIA_PATIENT_CLINICAL_STUDY_POST_FAILURE":
			return Object.assign({}, state, {
				isUpdatedCriteraPatient: false
			});
		case "REMOVE_CLINICAL_STUDY_POST_REQUEST":
			return Object.assign({}, state, {
				isRemovedClinicalStudy: false
			});
		case "REMOVE_CLINICAL_STUDY_POST_SUCCESS":
			return Object.assign({}, state, {
				isRemovedClinicalStudy: true
			});
		case "REMOVE_CLINICAL_STUDY_POST_FAILURE":
			return Object.assign({}, state, {
				isRemovedClinicalStudy: false
			});
		case "UPDATE_PATIENT_CLINICAL_STUDY_POST_REQUEST":
			return Object.assign({}, state, {
				isUpdatedPatient: false
			});
		case "UPDATE_PATIENT_CLINICAL_STUDY_POST_SUCCESS":
			return Object.assign({}, state, {
				isUpdatedPatient: true,
				listOfClinicalStudy: addUpdatedPatientToStudy(state, action)
			});
		case "UPDATE_PATIENT_CLINICAL_STUDY_POST_FAILURE":
			return Object.assign({}, state, {
				isUpdatedPatient: false
			});
		case "UPDATE_DATE_PAYMENT_POST_REQUEST":
			return Object.assign({}, state, {
				isDatePaymentUpdated: false
			});
		case "UPDATE_DATE_PAYMENT_POST_SUCCESS":
			const listOfClinicalStudyFilterUpdateDatePayment = Array.from(state.listOfClinicalStudy, (elem) => {
				if (elem._id === action.patient.study_id) { // Find study in state
					let patient = Array.from(elem.patient);
					patient.find(p => p._id === action.patient.patient_id).enrolment = action.patientEnrolment;
					return Object.assign({}, elem, { patient });
				}
				return elem;
			})

			return Object.assign({}, state, {
				isDatePaymentUpdated: true,
				listOfClinicalStudy: listOfClinicalStudyFilterUpdateDatePayment
			});
		case "UPDATE_DATE_PAYMENT_POST_FAILURE":
			return Object.assign({}, state, {
				isDatePaymentUpdated: false
			});
		case "FIND_CLINICAL_STUDY_BY_USER_REQUEST":
			return Object.assign({}, state, {
				isGettingClinicalStudyByUser: false
			});
		case "FIND_CLINICAL_STUDY_BY_USER_SUCCESS":
			return Object.assign({}, state, {
				isGettingClinicalStudyByUser: true,
				listOfClinicalStudy: action.listOfClinicalStudy
			});
		case "FIND_CLINICAL_STUDY_BY_USER_FAILURE":
			return Object.assign({}, state, {
				isGettingClinicalStudyByUser: false
			});
		case "SEARCH_CLINICAL_STUDY_REQUEST":
			return Object.assign({}, state, {
				isSearchingClinicalStudy: false
			});
		case "SEARCH_CLINICAL_STUDY_SUCCESS":
			return Object.assign({}, state, {
				isSearchingClinicalStudy: true,
				listOfClinicalStudy: action.listOfClinicalStudy
			});
		case "SEARCH_CLINICAL_STUDY_FAILURE":
			return Object.assign({}, state, {
				isSearchingClinicalStudy: false
			});
		case "UPDATE_HEALTH_CENTER":
			return Object.assign({}, state, {
				selectedHealthCenter: action.center
			});
		default:
			return state;
	}
}

/**
 * action = new, state = old
 * @param action { patient: {}, studyId }
 * @returns list of studies
 */
function addUpdatedPatientToStudy(state, action){

	let currentStudy = state.listOfClinicalStudy.find(study => study._id === action.studyId);
	const patients = currentStudy.patient;
	currentStudy.patient = undefined;
	currentStudy.patient = patients.map(patient =>
		patient._id === action.patient._id ? action.patient : patient
	);

	return state.listOfClinicalStudy.map(study =>
		study._id === action.studyId ? currentStudy : study
	);
}
