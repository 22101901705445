import { combineReducers } from 'redux';

import i18n from './i18n/i18nReducer';
import global from './global/globalReducer';
import clinicalStudy from './clinicalStudy/clinicalStudyReducer';
import replacement from './replacement/replacementReducer';
import logs from './logs/logsReducer';
import user from './user/userReducer';
import secretary from './secretary/secretaryReducer';
import professionalRecruitment from './professionalRecruitment/professionalRecruitment';
import professionalRecruitmentContract from './professionalRecruitmentContract/professionalRecruitmentContractReducer';

const appReducer = combineReducers({
    i18n,
    global,
    clinicalStudy,
    replacement,
    logs,
    user,
    secretary,
    professionalRecruitment,
    professionalRecruitmentContract
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
