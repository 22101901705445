import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import axios from 'axios';

import ConnectedIntlProvider from './components/i18n/ConnectedIntlProvider';
import rootReducer from './reducers';
import { getBearerToken } from './actions/authentication/authentication';

// import MOCK_BE from './actions/MOCK_BE';

const store = createStore(rootReducer, applyMiddleware(thunk));

// If we have authentication data in the session storage, use it right away
var token = sessionStorage.getItem('token');
var jwt = sessionStorage.getItem('jwt');
if(token) axios.defaults.headers['Authorization'] = "Bearer " + token;
if(jwt) axios.defaults.headers.common['jwtToken'] = jwt;

// If a 401 error happens, get a new bearer token and issue the request again
axios.interceptors.response.use(null, async (error) => {
    if (error.response && error.response.status === 401) {
        var callback = (token) => {
            // Add to header
            axios.defaults.headers['Authorization'] = "Bearer " + token;
            error.config.headers['Authorization'] = "Bearer " + token;

            // Add to storage
            sessionStorage.setItem('token', token);

            // Do the failing call again
            return axios.request(error.config).then((res) => {
                return Promise.resolve(res)
            });
        }

        // Get a new token
        return getBearerToken(callback);
    }
    else if (error.response && error.response.status === 403){
        const hash = window.location.hash;
        if(hash !== "/" && hash !== "/#" && hash !== "#/" && hash !== "/#/") window.location.href = "/";
    } 

    return Promise.reject(error);
});

ReactDOM.render(
    <Provider store={store}>
        <ConnectedIntlProvider>
            <App />
        </ConnectedIntlProvider>
    </Provider>
    , document.getElementById('root')
);

