export default function us(state = {}, action) {
	switch(action.type) {
        case "FIND_NOT_GRANTED_AWAITING_USERS_REQUEST":
			return Object.assign({}, state, {
				isGettingUsersNotGrantedAwaiting: true
			});
		case "FIND_NOT_GRANTED_AWAITING_USERS_SUCCESS":
			return Object.assign({}, state, {
				usersAwaiting: action.usersAwaiting,
                usersAwaitingCount: action.usersAwaitingCount,
				isGettingUsersNotGrantedAwaiting: false
			});
		case "FIND_NOT_GRANTED_AWAITING_USERS_FAILURE":
			return Object.assign({}, state, {
				isGettingUsersNotGrantedAwaiting: false
			});
		case "FIND_USERS_FOR_PROFESSIONAL_RECRUITMENT_REQUEST":
			return Object.assign({}, state, {
				isGettingProfessionalsToRecruit: true
			});
		case "FIND_USERS_FOR_PROFESSIONAL_RECRUITMENT_SUCCESS":	
			return Object.assign({}, state, {
				professionalsToRecruit: action.professionalsToRecruit,
				professionalsToRecruitCount: action.professionalsToRecruitCount,
				professionalsApprovalCount: action.professionalsApprovalCount,
				professionalsRefusalCount: action.professionalsRefusalCount,
				isGettingProfessionalsToRecruit: false
			});
		case "FIND_USERS_FOR_PROFESSIONAL_RECRUITMENT_FAILURE":
			return Object.assign({}, state, {
				isGettingProfessionalsToRecruit: false
			});	
		case "CLEAR_RECRUITMENT":
			return Object.assign({}, state, {
				professionalsToRecruit: null,
				professionalsToRecruitCount: 0,
				professionalsApprovalCount: 0,
				professionalsRefusalCount: 0,
			});
		default:
			return state;
	}
}