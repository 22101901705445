import APIUrl from "../APIUrl";
import axios from 'axios';

var qs = require('qs');

export const LOGOUT = "LOGOUT";

export function logoutAction(){return {type: LOGOUT}};

export const getBearerToken = function (callback) {
    var postData = {
        "grant_type": "client_credentials"
    };
    
    var config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Basic aHlkcm9nZW5taWNodG86cGVsb2h5ZHJvQWhmcmUz"
        }
    };
    
    return axios.post(APIUrl.getBearerToken, qs.stringify(postData), config)
            .then(function(response){
                if(callback) return callback(response.data.access_token);
            })
            .catch(function(err){
                throw err;
            });
};

export const login = function(data, callback){
    return function(dispatch){
        return axios.post(APIUrl.login, data)
            .then(function(response){
                // Add jwt token to headers
                axios.defaults.headers.common['jwtToken'] = response.headers.jwttoken;

                // Add JWT token to storage
                sessionStorage.setItem('jwt', response.headers.jwttoken);
                
                if(callback) return callback();
            })
            .catch(function(err){
                if(callback) return callback(err);
            });
    };
};

export const logout = function(callback){
    return function(dispatch){
        // Delete tokens
        delete axios.defaults.headers.common['jwtToken'];
        delete axios.defaults.headers.jwtToken;
        delete axios.defaults.headers.common['Authorization'];

        // Clear session storage
        sessionStorage.clear();
    
        dispatch(logoutAction());

        callback();
    };
};