export default function professionalRecruitmentContract(state = {}, action) {
	switch(action.type) {
		case "GENERATE_PROFESSIONAL_RECRUITMENT_CONTRACT_REQUEST":
			return Object.assign({}, state, {
				isGeneratingContract: true
			});
		case "GENERATE_PROFESSIONAL_RECRUITMENT_CONTRACT_SUCCESS":
			return Object.assign({}, state, {
				professionalRecruitmantContract: action.professionalRecruitmantContract.professionalRecruitmantContract,
				isGeneratingContract: false
			});
		case "GENERATE_PROFESSIONAL_RECRUITMENT_CONTRACT_FAILURE":
			return Object.assign({}, state, {
				isGeneratingContract: false
			});
		default:
			return state;
	}
}