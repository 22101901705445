export default function replacement(state = {}, action) {
    switch (action.type) {
        case "GET_REPLACEMENT_CANDIDATES_REQUEST":
            return Object.assign({}, state, {
                isGettingCandidates: false
            });
        case "GET_REPLACEMENT_CANDIDATES_SUCCESS":
            return Object.assign({}, state, {
                isGettingCandidates: true,
                possibleCandidates: action.possibleCandidates
            });
        case "GET_REPLACEMENT_CANDIDATES_FAILURE":
            return Object.assign({}, state, {
                isGettingCandidates: false
            });
        default:
            return state;
    }
}