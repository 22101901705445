

export default function logs(state = {}, action) {
	switch(action.type) {
		case "LOGS_GET_SUCCESS":
			return Object.assign({}, state, {
				logs: action.logs,
				logsCount: action.logsCount
			})
		default:
			return state;
	}
}