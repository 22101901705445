export default class Util {

    static contains(array, obj) {
        if (!array || array.length === 0) return false;

        for (let a of array) if (a === obj) return true;
        return false;
    }

    static null(val) {
        return (val === undefined || val === null);
    }

    static emptyString(val) {
        return (Util.null(val) || ("" + val).replace(" ", "") === "");
    }

    static emptyObject(val) {
        return Util.null(val) || Object.keys(val).length <= 0;
    }

    static isEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static isPhone(phone) {
        var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
        return re.test(String(phone).toLowerCase());
    }

    static truncate(txt, size) {
        if (Util.emptyString(txt)) return txt;

        if (txt.length > size) return txt.substring(0, size).trim() + "...";
        else return txt;
    }

    static formatNbr(nbr) {
        if (Util.null(nbr)) return;

        var parts = nbr.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".").replace(".", ",");
    }

    static fstLetUpCase(txt) {
        return txt.charAt(0).toUpperCase() + txt.slice(1);
    }

    static clone(obj) {
        return Object.assign({}, obj);
    }

    static stringify(obj, aspect, indent) {
        var result;

        try {
            result = JSON.stringify(obj, aspect, indent);
        } catch (e) {
            result = {};
        }

        return result;
    }

    static parse(txt) {
        return JSON.parse(txt);
    }

    static formatPhoneNumber(nbr) {
        return nbr.toString().replace(/\B(?=(\d{2})+(?!\d))/g, " ");
    }

    static isRoleTemporary(role) {
        if (role.startsWith('temporary')) return true;
        else return false;
    }

    static checkAvatar(user) {
        let avatar;
        if (!user.role || this.isRoleTemporary(user.role)) {
            if (user.gender === 'woman') avatar = 100;
            else avatar = 99;
        }
        else
            avatar = user.image;

        return avatar;
    }

    /**
     * @param {object} characteristics 
     * @param {boolean} short returns only route, postal code and city
     * @returns street number route city postal code with comma between
     */
    static getUserAddress(characteristics, short = true) {
        const { route, city, postal_code, street_number } = characteristics.address_components;
        let address = null;
        if(short) address = (route ? route : '') + ' ' + (postal_code ? postal_code + ', ' : '')  + (city ? city : '');
        else address = (street_number ? street_number + ' ' : '') + (route ? route : '') + ', ' + (city ? city + ', ' : '') + (postal_code ? postal_code : '')  ;
        
        return address;
    }

    /**
     * @param {object} address_components 
     * @returns address formated for sendinblue/brevo contact import
     */
    static getSbUserAddress(address_components) {
        const formatedComponents = this.cleanAddressComponent({...address_components});
        const {route, city, postal_code, street_number} = formatedComponents;
        let address = street_number + " " + route + " " + city + " " + postal_code;
        return address;
    }

    /**
     * @description Check if component has default variables
     * @param {object} address_components 
     * @returns 
     */
    static cleanAddressComponent(address_components){
        const comp = address_components;
        
        let obj = {
            region: comp.region === "Region" ? "" : comp.region,
            department: comp.department === "Departement" ? "" : comp.department,
            city: comp.city === "Ville" ? "" : comp.city,
            country: comp.country === "Pays" ? "" : comp.country,
            route: comp.route === "Adresse" ? "" : comp.route,
            postal_code: comp.postal_code === "00000" ? "" : comp.postal_code,
            street_number: comp.street_number === undefined ? "" : comp.street_number 
        }

        return this.replaceEmptyFieldsFromObject(obj);
    }

    static replaceEmptyFieldsFromObject(obj, replaceObj= ""){
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] === undefined || obj[key] === null) {
                    obj[key] = replaceObj;
                } else if (typeof obj[key] === 'object') {
                    obj[key] = this.replaceEmptyFieldsFromObject(obj[key]);
                }
            }
        }

        return obj;
    }

    static getRoleTranslation (role) {
        let roleName;
        switch (role) {
            case "doctor":
                roleName = "Docteur";
                break;
            case "substitute":
                roleName = "Docteur remplaçant";
                break;
            case "psychologist_install":
                roleName = "Psychologue";
                break;
            case "radiologist_install":
                roleName = "Radiologue";
                break;
            case "pediatrician_install":
                roleName = "Pédiatre";
                break;
            case "psychiatrist_install":
                roleName = "Psychiatre";
                break;
            default:
                break;
        }
        return roleName;
    }

    static getProfessionDiminutive(role) {
        let diminutive;
        switch (role) {
            case "doctor":
            case "temporary":
                diminutive = 'M';
                break;
            case "substitute":
            case "temporary_sub":
                diminutive = 'R';
                break;
            case "psychologist_install":
            case "temporary_psy":
                diminutive = 'Psy';
                break;
            case "radiologist_install":
            case "temporary_rad":
                diminutive = 'Rad';
                break;
            case "pediatrician_install":
            case "temporary_ped":
                diminutive = 'Ped';
                break;
            case "psychiatrist_install":
            case "temporary_pst":
                diminutive = 'Pst';
                break;
            default:
                break;
        }
        return diminutive;
    }

    static isSubstitute(role) {
        let isSub = false;
        switch (role) {
            case "substitute":
            case "temporary_sub":
                isSub = true;
                break;
            default:
                break;
        }
        return isSub;
    }

    static isMailTemporary(mail) {
        const isMailTemporary = new RegExp('^[A-Za-z0-9._%+-]+@hydrogeninternaluser.com$'); // All emails ending by @hydrogeninternaluser.com  

        if (isMailTemporary.test(mail)) return true;
        else return false;
    }

    static getGenderTranslation(gender) {
        if (gender === "man") return "masculin";
        else if (gender === "woman") return "féminin";
        else return '';
    }

    static isLicensedDoctor(role) { // With diploma
        let license = true;
        switch (role) {
            case "psychologist_install":
            case "temporary_psy":
                license = false;
                break;
            default:
                break;
        }
        return license;
    }

    static isObject(obj) {
        return typeof obj === 'object' && obj !== null;
    }
}