import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import 'moment/locale/fr';


const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const Homepage = Loadable({
    loader: () => import('./containers/Homepage'),
    loading
});

// pages
const Login = Loadable({
    loader: () => import('./views/pages/Login'),
    loading
});

const Page404 = Loadable({
    loader: () => import('./views/pages/Page404'),
    loading
});

const Page500 = Loadable({
    loader: () => import('./views/pages/Page500'),
    loading
});


export default class App extends Component {

    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/404" name="Page 404" component={Page404} />
                    <Route exact path="/500" name="Page 500" component={Page500} />
                    <Route path="/home" name="Home" component={Homepage} />
                    <Route path="/" name="Login" component={Login} />
                </Switch>
            </HashRouter>
        );
    }
};
